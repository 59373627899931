import './App.css';
// import logo from './images/deez.gif'

function App() {
  return (
    <div className="App">
      <div className="frame">
        {/* <img className="jus" src={logo} alt="deez"/> */}
      </div>
    </div>
  );
}

export default App;
